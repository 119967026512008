<template>
  <div class="page-container">
    <div class="banner" :style="{background: `url(${bannerUrl}) no-repeat center`}">
      <div class="banner-tab">
        <div class="banner-left">选择适合你的包装类型</div>
        <div class="banner-right">
          <span :class="{'active': activeTab == 1}" @click="changeTab(1)">PE重包装袋</span>
          <span :class="{'active': activeTab == 2}" @click="changeTab(2)">PE阀口袋</span>
        </div>
      </div>
      <div class="banner-info">
        <div class="banner-info-left">
          <img v-if="activeTab == 1" class="oneImg" src="../../assets/image/one.png" alt>
          <img v-if="activeTab == 2" class="twoImg" src="../../assets/image/two.png" alt>
        </div>
        <div class="banner-info-right">
          <div class="banner-subtitle">{{tabList[activeTab-1].subtitle}}</div>
          <div class="banner-title">{{tabList[activeTab-1].title}}</div>
          <div class="banner-discript">{{tabList[activeTab-1].discripe}}</div>
          <router-link :to="{path: '/customized', query: {packageType: activeTab, areaType: '化工'}}">
            <div class="dingzhi">
              <span>立即定制</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="page-content-div">
      <div class="page-content">
        <div class="about-us-content">
          <h2 class="module-title">关于我们</h2>
          <div class="text-div">
            <div class="text-contain">
              <h3>温州丹业包装公司</h3>
              <p>
                创建于2009年，位于有“中国塑编城”和“中国信用镇”之称的温州萧江。作为国内15家能专业生产FFS重包装袋之一的公司，注册资本3300万元，总投资5800万元，占地12000平米
                目前拥有四条FFS袋全自动生产线，包括性能的三层共挤吹膜机组，以及先进的专业印刷机、压花—插边机、电脑高速制袋机等。引进德国巴顿菲尔三层共挤吹膜机，让企业产能和产品性能再上一个台阶
              </p>
              <router-link to="/enterprise-introduction">
                <button class="liaojie">
                  了解更多
                  <i class="el-icon-arrow-right"></i>
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="lingyu">
          <h2 class="module-title">应用领域</h2>
          <div class="domain-info">
            <div class="infos info-one">
              <div class="info-img huagong-img" @click="toTpplicationArea(1)">
                <p>化工</p>
                <div class="area-info">
                  <span>危包化学原料及流程的案例与技术解决方案</span>
                </div>
              </div>
              <div class="info-img jiancai-img" @click="toTpplicationArea(3)">
                <p>建材</p>
                <div class="area-info">
                  <span>成本优势与载重强度的案例与技术解决方案</span>
                </div>
              </div>
              <div class="info-img siliao-img" @click="toTpplicationArea(5)">
                <p>饲料</p>
                <div class="area-info">
                  <span>针对饲料透气性、发酵密封需求的案例与技术解决方案</span>
                </div>
              </div>
            </div>
            <div class="infos info-two">
              <div class="info-img suliao-img" @click="toTpplicationArea(2)">
                <p>塑料</p>
                <div class="area-info">
                  <span>满足特殊性质物料抗静电与氧化需求的案例与技术解决方案</span>
                </div>
              </div>
              <div class="info-img nongfei-img" @click="toTpplicationArea(4)">
                <p>农肥</p>
                <div class="area-info">
                  <span>满足物料避光等性质与成本优势的案例与技术解决方案</span>
                </div>
              </div>
              <div class="info-img shipin-img" @click="toTpplicationArea(6)">
                <p>食品</p>
                <div class="area-info">
                  <span>食包质量的案例与技术解决方案</span>
                </div>
              </div>
            </div>
            <div class="info-three">
              <div class="qita-img" @click="toTpplicationArea(7)">
                <p>其他用途</p>
                <div class="area-info">
                  <span>将袋子性能用于物流运输领域和成本优势的案例与技术解决方案</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="news">
          <h2 class="module-title">热门资讯</h2>
          <div class="news-list">
            <div class="news-info" v-for="(item, index) in dataList" :key="index">
              <div class="img-content">
                <img :src="item.imgUrl" alt="资讯图片">
              </div>
              <p class="news-title">{{item.title}}</p>
              <small>{{item.newsTime}}</small>
              <div class="option-btn">
                <router-link
                  :to="{path: '/latest-consultation/consultation-info', query: {id: item.id}}"
                >
                  <el-button type="primary" plain>查看详情</el-button>
                </router-link>
                <router-link
                  :to="{path: '/application-area', query: {areaType: item.applicationRange}}"
                >
                  <el-button type="primary" plain>
                    领域
                    <span v-if="item.applicationRange">: {{typeListObj[item.applicationRange]}}</span>
                  </el-button>
                </router-link>
              </div>
            </div>
          </div>
          <router-link to="/latest-consultation">
            <div class="more-news">
              更多新闻资讯
              <i class="el-icon-arrow-right"></i>
            </div>
          </router-link>
        </div>

        <div class="costmers">
          <div class="costmers-info">
            <p class="costmer-left-one">我们的客户</p>
            <p class="costmer-left-two">品牌的力量，他们选择了我们</p>
          </div>

          <el-carousel indicator-position="outside" height="100px">
            <el-carousel-item v-for="(item, index) in  this.logos" :key="index">
              <div class="partner-logo">
                <img v-for="(itemImg, indexImg) in item" :key="indexImg" :src="itemImg.imgUrl" alt>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      tabList: [
        {
          subtitle: "专注PE重包装袋领域",
          title: "我们只生产PE重包装",
          discripe:
            "PE重包装袋为FFS袋的统称，为制(Film)-装料(Fill)-封口 (Seal)一体化的缩写，是欧洲最新发展起来的一种告诉包装技术，包装效率高且成本低，是包装行业中的新兴产品"
        },
        {
          subtitle: "专注PE重包装袋领域",
          title: "阀口袋制作专家",
          discripe:
            "PE阀口袋，俗称糊底袋，为目前国际流行包装 袋，从顶部或底部阀口进料，采用专门灌装设备，灌 装物料后装成方体，堆包整齐，美观，属于环保袋，是包装行业中的新兴产品"
        }
      ],
      activeTab: 1,
      activeImg: "",
      dataList: [],
      bannerList: [],
      logos: [],
      typeListObj: {},
      bannerObj: {},
      bannerUrl: ""
    };
  },
  created() {
    this.bannerUrl = this.$url.backImg[1];
    this.typeListObj = this.$url.typeListObj;
    this.getBanners();
    this.getList();
    this.getLogos();
  },
  mounted() {
    this.activeImg = this.tabList[this.activeTab - 1].img;
  },
  methods: {
    getBanners() {
      let url = this.$url.getBackendApiUrl(`/api/banner/list`);
      url = this.$url.addQueryString(url, "pageNum", 1);
      url = this.$url.addQueryString(url, "pageSize", 30);
      this.$http.post(url, {}).then(
        res => {
          if (res.data.code == 200) {
            this.bannerList = res.data.data.list;
            this.bannerObj = {};
            res.data.data.list.forEach(item => {
              this.bannerObj[item.type] = item;
            });
            if (this.bannerObj[1]) {
              this.bannerUrl = this.bannerObj[1]["imgUrl"];
            }
            localStorage.setItem("banners", JSON.stringify(this.bannerObj));
          }
        },
        () => {}
      );
    },

    getLogos() {
      let url = this.$url.getBackendApiUrl(`/api/logo/list`);
      url = this.$url.addQueryString(url, "pageNum", 1);
      url = this.$url.addQueryString(url, "pageSize", 40);

      this.$http.post(url, {}).then(
        res => {
          if (res.data.code == 200) {
            const resultNew = res.data.data.list;
            const result = resultNew.filter(item => item.logoType == 1);
            for (let i = 0; i < result.length; ) {
              //注意：这里与for循环不太一样的是，没有i++
              this.logos.push(result.slice(i, (i += 5)));
            }
            console.log(this.logos);
          }
        },
        () => {}
      );
    },

    changeTab(item) {
      this.activeTab = item;
    },

    toTpplicationArea(item) {
      this.$router.push({
        path: "/application-area/index",
        query: {
          areaType: item
        }
      });
    },
    getList() {
      const url = this.$url.getBackendApiUrl(
        `/api/news/list?&pageSize=3&pageNum=1`
      );
      this.$http.post(url).then(res => {
        this.dataList = res.data.data.list;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container .banner {
  height: 550px;
  width: 100%;
  background: #ccc;
  background: url("../../assets/banner/shouye_bg_banner.min.png") no-repeat
    center;
  .banner-tab {
    width: 1200px;
    margin: 0 auto;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 36px;
    .banner-left {
      font-size: 30px;
      font-family: PingFangSC-Medium;
      color: #ffffff;
      font-weight: bolder;
    }
    .banner-right span {
      font-size: 24px;
      width: 113px;
      height: 33px;
      color: rgba(255, 255, 255, 100);
      font-size: 20px;
      text-align: center;
      font-family: PingFangSC-Medium;
      border: 2px solid rgba(255, 255, 255, 0.3);
      padding: 8px 15px;
      cursor: pointer;
    }
  }
  .banner-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 40px;
    .banner-info-left {
      margin-left: 65px;
      width: 410px;
      text-align: center;
      .oneImg {
        width: 196px;
        height: 407px;
      }
      .twoImg {
        width: 245px;
        height: 379px;
      }
    }
    .banner-info-right {
      width: 550px;
      text-align: left;
      font-family: PingFangSC-Semibold;
      color: rgba(255, 255, 255, 100);
      .banner-subtitle {
        font-size: 32px;
        text-align: left;
      }
      .banner-title {
        font-size: 54px;
        margin: 12px 0;
      }
      .banner-discript {
        font-size: 16px;
        text-align: justify;
      }
      .dingzhi {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 176px;
        height: 60px;
        color: #fff;
        font-size: 20px;
        margin-top: 36px;
        background-color: rgba(57, 63, 227, 1);
        box-shadow: 0px 6px 12px 0px rgba(10, 12, 61, 0.2);
        padding-left: 20px;
        padding-right: 8px;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          right: -16px;
          top: 0;
          width: 0;
          height: 0;
          border: 22px solid;
          border-width: 33px 0 27px 16px;
          border-color: transparent transparent transparent #393fe3;
        }
        span {
          padding-right: 4px;
        }
      }
      .dingzhi:hover {
        background-color: #262aa2;
        &::before {
          border-color: transparent transparent transparent #262aa2;
        }
      }
    }
  }
}

.page-content-div {
  display: flex;
  justify-content: center;
  width: 100%;
  .page-content {
    width: 1200px;
    min-height: 400px;
  }
}
.module-title {
  text-align: center;
  color: rgba(5, 6, 16, 100);
  margin: 70px 0 57px 0;
}
.about-us-content {
  .text-div {
    background: url(../../assets/image/guanwang_bg_jianjie.png) no-repeat;
    height: 300px;
    .text-contain {
      margin-left: 35%;
      color: #ffffff;
      padding: 25px 50px;
      p {
        margin: 25px 0;
        line-height: 30px;
      }
      .liaojie {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 48px;
        color: #fff;
        font-size: 20px;
        margin-top: 36px;
        background-color: rgba(57, 63, 227, 1);
        border: 1px solid rgba(255, 255, 255, 0.2);
        padding-left: 20px;
        padding-right: 8px;
        position: relative;
      }
      .liaojie:hover {
        border: 1px solid #fff;
      }
    }
  }
}

.domain-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.infos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-img {
  height: 204px;
  width: 384px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.6s ease-in-out;
  p {
    color: rgba(255, 255, 255, 100);
    font-size: 25px;
    text-align: left;
    font-family: PingFangSC-Medium;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  .area-info {
    display: none;
    width: 100%;
    font-size: 16px;
    text-align: left;
    font-family: PingFangSC-Regular;
    margin: 0 12px 20px 10px;
    span {
      color: #fff;
      word-break: break-all;
      padding-right: 20px;
    }
  }
}

.info-img:hover {
  transform: scale(1.03, 1.03);
  p {
    margin-bottom: 0;
  }
  .area-info {
    display: block;
  }
}

.huagong-img {
  background: url(../../assets/image/guanwang_bg_huagong.png);
}
.jiancai-img {
  background: url(../../assets/image/guanwang_bg_jiancai.png);
}
.siliao-img {
  background: url(../../assets/image/guanwang_bg_siliao.png);
}
.suliao-img {
  background: url(../../assets/image/guanwang_bg_suliao.png);
}
.nongfei-img {
  background: url(../../assets/image/guanwang_bg_nongfei.png);
}
.shipin-img {
  background: url(../../assets/image/guanwang_bg_shipin.png);
}

.qita-img {
  height: 662px;
  width: 384px;
  background: url(../../assets/image/guanwang_bg_qita.png);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.6s ease-in-out;
  p {
    color: rgba(255, 255, 255, 100);
    font-size: 25px;
    text-align: left;
    font-family: PingFangSC-Medium;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  .area-info {
    display: none;
    width: 100%;
    font-size: 16px;
    text-align: left;
    font-family: PingFangSC-Regular;
    margin: 0 12px 20px 10px;
    span {
      color: #fff;
      word-break: break-all;
      padding-right: 20px;
    }
  }
}

.qita-img:hover {
  transform: scale(1.03, 1.03);
  p {
    margin-bottom: 0;
  }
  .area-info {
    display: block;
  }
}

.news-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.news-info {
  width: 384px;
  height: 320px;
  border: 1px solid #ccc;
  /*cursor: pointer;*/
  transition: all 0.6s;
  .img-content {
    height: 204px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  p {
    padding: 10px;
  }
  small {
    padding: 10px 10px 20px 10px;
    color: #666;
  }
}

.news-info:hover {
  transform: scale(1.03);
}

.more-news {
  margin: 50px 0;
  color: rgba(57, 63, 227, 100);
  font-size: 18px;
  text-align: center;
  font-family: PingFangSC-Medium;
  cursor: pointer;
}

.active {
  border: 2px solid rgba(255, 255, 255, 1) !important;
}

.costmers {
  margin-top: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .costmers-info {
    font-family: PingFangSC-Semibold;
    .costmer-left-one {
      color: rgba(5, 6, 16, 100);
      font-size: 28px;
    }
    .costmer-left-two {
      color: rgba(128, 128, 134, 100);
      font-size: 18px;
    }
  }
}

.partner-logo {
  img {
    width: 190px;
  }
}
.option-btn {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  margin: 10px 0;
}

.news-title {
  display: inline-block;
  white-space: nowrap;
  width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>